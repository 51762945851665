import { onFind } from '@elements/init-modules-in-scope'
import { on } from '@elements/dom-utils'

const selectors = {
    dotsContainer: ".js-teaser-slider-area__dots-container",
    dotsButton: ".js-scroll-slider__dots-btn"
}

export function init() {
    onFind(selectors.dotsContainer, (container) => {
        onFind(selectors.dotsButton, (dotsBtn) => {
            on("click", () => {
                const leftToActiveButtonCenter = dotsBtn.getBoundingClientRect().left + dotsBtn.getBoundingClientRect().width / 2

                container.scrollTo({
                    left: leftToActiveButtonCenter - container.getBoundingClientRect().width / 2 - container.getBoundingClientRect().left + container.scrollLeft,
                    behavior: "smooth"
                })
            }, dotsBtn)
        })
    })
}