import {findAll} from '@elements/dom-utils';

export function init() {
    window.onload = function() {
        var urlHash = window.location.hash;

        if (urlHash) {
            var tabs = Array.from(findAll(".js-nav-link"));

            if (tabs.length > 0) {
                tabs.forEach((tab) => {
                    var idAttribute = tab.getAttribute('id');

                    if (idAttribute) {
                        var urlId = urlHash.substring(urlHash.length - 1);
                        var elemId = idAttribute.substring(idAttribute.length - 1);

                        if (urlId === elemId) {
                            tab.click();
                            setTimeout(() => tab.scrollIntoView(), 0);
                        }
                    }
                });
            }
        }
    };
}


