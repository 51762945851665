"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {find, findIn, addClass, findAllIn, on, toggleClass, findAll} from '@elements/dom-utils';

const selectors = {
    toggle: '.js-toggle-active',
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init () {

    if (!isMobile()) {
        return;
    }


    onFind(selectors.toggle, function (baseElement) {
        let questions = findAll(selectors.toggle);
        questions.forEach(function (el) {
            on('click', function (){
                toggleClass('active', el);
            }, el);
        });
    });
}